.Description {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5em;
  flex-direction: column;
  text-align: center;
  color: black;
}
.Description .Subheading {
  font-weight: 500;
  color: var(--hap-base-color);
  font-family: var(--primary-font-family);
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.Headingh1 {
  color: black;
  font-weight: 500;
  font-size: 45px;
  text-align: center;
  font-family: var(--heading-font-family);
}
.AboutSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 5%;
  color: black;
  margin: 3em 0em;
}
.LeftSection {
  width: 40%;
}
.LeftSection img {
  width: 100%;
  object-fit: cover;
}
.RightSection {
  width: 40%;
  color: black;
}
.RightSection .Subheading {
  color: var(--hap-base-color);
}
.Subheading {
  font-weight: 500;
  font-family: var(--primary-font-family);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.RightSection h1 {
  font-weight: 500;
  font-size: 50px;
  font-family: var(--heading-font-family);
}
.paragraph {
  font-size: 18px;
  text-align: justify;
}
.paragraph a {
  text-decoration: none;
  color: var(--hap-base-color);
  font-style: italic;
}
.paragraph a:hover {
  text-decoration: underline;
}
.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.item h1 {
  font-size: 75px;
  margin: 0;
  letter-spacing: 0.1em;
}
.item p {
  margin: 0;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.Amendities {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5em;
}
.Amendities .Headingh1 {
  margin: 10px;
}
.Amendities .Subheading {
  text-align: center;
  margin: 10px;
  color: var(--hap-base-color);
}
.Amendities .paragraph {
  text-align: center;
  width: 50%;
  margin: 10px;
  line-height: 2em;
}
.Amenditiesimages {
  display: grid;
  width: 88%;
  overflow: hidden;
  margin-bottom: 5em;
  gap: 20px;
  margin-top: 5em;
  grid-template-columns: repeat(4, 1fr);
}
.griditem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  height: 60vh;
}
.griditem img {
  width: 100%;
  transition: transform 1s ease;
}
.griditem img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.griditem .overlap {
  background-color: #2c3529;
  opacity: 0.8;
  position: absolute;
  width: 100%; /* Set width to 100% */
  height: 100%; /* Set height to 100% */
  top: 0;
  z-index: 1;
  left: 0;
}
.griditem .overlapLine {
  position: absolute;
  width: 91.5%; /* Set width to 100% */
  height: 94%; /* Set height to 100% */
  top: 3%;
  left: 4%;
  z-index: 1;
  border: 1px solid #b99d75;
}
.griditem:hover .overlapLine + img {
  transform: scale(1.1); /* Adjust the scale factor as needed */
  transition: transform 0.3s ease-out; /* Add a smooth transition effect */
}
.griditem p {
  font-family: var(--primary-font-family);
  z-index: 1;
  width: 70%;
  font-size: 12px;
  font-weight: 500;
  color: white;
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0 0 30px 0;
}
.griditem h1 {
  margin: 0 0 10px 0;
  font-family: var(--heading-font-family);
  z-index: 1;
  width: 90%;
  color: white;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0.25px;
  text-align: center;
}
.griditem p a {
  text-decoration: none;
  color: inherit;
  position: relative;
  font-family: var(--heading-font-family);
  font-size: 16px;
}
.griditem p a::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 0.5px; /* Adjust the thickness of the underline as needed */
  background-color: #b99d75;
  transform-origin: bottom right;
  transform: scaleX(1);
  transition: transform 0.3s ease;
}
.griditem p a:hover::before {
  transform-origin: bottom left;
  transform: scaleX(0);
}
@media only screen and (max-width: 1250px) {
  .AboutSection {
    gap: 2%;
  }
  .LeftSection {
    width: 40%;
  }
  .RightSection {
    width: 40%;
  }
  .RightSection h1,
  .LeftSection h1 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 1000px) {
  .AboutSection {
    flex-direction: column;
  }
  .LeftSection:nth-child(1) {
    width: 70%;
  }
  .RightSection:nth-child(2) {
    width: 70%;
  }
  .RightSection:nth-child(1) {
    width: 70%;
  }
  .LeftSection:nth-child(2) {
    width: 70%;
  }
  .RightSection h1,
  .LeftSection h1 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 900px) {
  .Headingh1 {
    font-size: 40px;
  }
  .Headingh1 br {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .Headingh1 {
    font-size: 36px;
  }
  .RightSection h1,
  .LeftSection h1 {
    font-size: 30px;
  }
  .RightSection p,
  .LeftSection p {
    font-size: 16px;
  }
}
