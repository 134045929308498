.Container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5%;
  margin: 4em 0;
}
.gridItem {
  margin-top: 3em;
  width: 27%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gridItem .imgDiv1 {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.gridItem .imgDiv1 img {
  width: 100%;
  filter: brightness(0.7);
  transform: scale(1.1);
  transition: transform 0.3s ease, filter 0.3s ease;
}
.gridItem .imgDiv1:hover img {
  transform: scale(1.2);
  filter: brightness(0.6);
}

.gridItem .imgDiv1 .border {
  border: 2px solid#b99d75;
  width: 90%;
  height: 93%;
  position: absolute;
  left: 4%;
  top: 3%;
}
.gridItem .imgDiv1 .text {
  position: absolute;
  bottom: 2%;
  left: 1.5em;
  width: 85%;
  text-align: center;
}
.gridItem .imgDiv1 .text h2 {
  letter-spacing: 1px;
  font-family: var(--heading-font-family);
  font-size: 30px;
}
.gridItem .imgDiv1 .text p {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.5px;
  font-family: var(--secondary-font-family);
}
@media only screen and (max-width: 1150px) {
  .Container {
    flex-wrap: wrap;
  }
  .gridItem {
    width: 40%;
  }
}
@media only screen and (max-width: 750px) {
  .Container {
    flex-wrap: wrap;
  }
  .gridItem {
    width: 40%;
  }
}
@media only screen and (max-width: 700px) {
  .Container {
    flex-wrap: wrap;
  }
  .gridItem {
    width: 60%;
  }
}
@media only screen and (max-width: 530px) {
  .gridItem .imgDiv1 .text h2 {
    letter-spacing: 1px;
    font-family: var(--heading-font-family);
    font-size: 25px;
  }
  .gridItem {
    width: 75%;
  }
  .gridItem {
    margin-top: 1.5em;
  }
  .gridItem .imgDiv1 .text p {
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.5px;
  }
}
