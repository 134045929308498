.Description {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4em;
  flex-direction: column;
  /* text-align: center; */
  color: black;
}
.Description .Subheading {
  font-weight: 500;
  color: var(--hap-base-color);
  font-family: var(--primary-font-family);
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
}
.Headingh1 {
  color: black;
  font-weight: 500;
  font-size: 45px;
  text-align: center;
  font-family: var(--heading-font-family);
}
.MoreDescription {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MoreDescription p {
  width: 50%;
  margin: 0;
}
.selectRooms {
  width: 88%;
  margin: 2em 0;
}
.selectRooms h4 {
  text-align: left;
  color: var(--hap-base-color);
  font-family: var(--primary-font-family);
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
  line-height: 1.2;
}
.selectRooms h1 {
  font-family: var(--heading-font-family);
  font-size: 3.75rem;
  line-height: 1.2;
  color: black;
  font-weight: 500;
  margin: 30px 0;
}
.selectRooms p {
  margin: 0;
  font-size: 18px;
  color: black;
  line-height: 1.66;
}
.RoomOptions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 3.5em 0em 1em 0em;
}
.RoomOptions .Item {
  width: 48%;
}
.RoomOptions .Item .Img {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.RoomOptions .Item .Img img {
  width: 100%;
  scale: 1.1;
  transition: transform 1s ease;
}
.RoomOptions .Item .Img img:hover {
  transform: scale(1.2);
}
.RoomOptions .Item .Img .PriceDiv {
  position: absolute;
  background-color: white;
  color: black;
  top: 15px;
  left: 15px;
  font-family: var(--heading-font-family);
}
.PriceDiv p {
  padding: 0px 5px;
}
.Item h1 {
  font-size: 35px;
  margin: 15px 0;
}
.Item .info {
  font-size: 25px;
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
}
.Item .info div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.Item .info span {
  font-size: 15px;
  color: black;
  font-family: var(--heading-font-family);
  margin-right: 20px;
  margin-left: 0px;
}
.Item .info img {
  height: 27px;
}
.Item p {
  font-size: 17px;
  color: #333632;
  font-family: var(--primary-font-family);
}
.explorepara {
  font-family: var(--primary-font-family);
  z-index: 1;
  width: 70%;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin: 0 0 20px 0;
}
.explorepara a {
  text-decoration: none;
  color: inherit;
  position: relative;
  font-family: var(--heading-font-family);
  font-size: 16px;
}
.explorepara a::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 0.5px; /* Adjust the thickness of the underline as needed */
  background-color: #b99d75;
  transform-origin: bottom right;
  transform: scaleX(1);
  transition: transform 0.3s ease;
}
.explorepara a:hover::before {
  transform-origin: bottom left;
  transform: scaleX(0);
}
@media only screen and (max-width: 1000px) {
  .RoomOptions {
    flex-wrap: wrap;
  }
  .RoomOptions .Item {
    width: 78%;
    margin-bottom: 2em;
  }
  .MoreDescription p {
    width: 70%;
  }
}
@media only screen and (max-width: 670px) {
  .Headingh1 {
    font-size: 40px;
  }
  .MoreDescription p {
    width: 70%;
  }
}
@media only screen and (max-width: 600px) {
  .Headingh1 {
    font-size: 35px;
  }
  .MoreDescription p {
    width: 70%;
    font-size: 15px;
  }
}
@media only screen and (max-width: 500px) {
  .Headingh1 br {
    display: none;
  }
}
@media only screen and (max-width: 470px) {
  .Item .info {
    font-size: 15px;
    flex-wrap: wrap;
  }
  .Item .info span {
    font-size: 13px;
  }
}
