.HeroSection {
  height: 90vh;
  background-attachment: fixed;
  background-size: cover;
}
.modalVideo {
  width: 100%;
  height: 100%;
}
.CloseButton {
  position: absolute;
  z-index: 999;
  top: 5%;
  left: 46%;
  color: white;
  background-color: #53624e;
  padding: 5px 10px;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: var(--heading-font-family);
  animation: heartbeat 5s infinite ease-in-out;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
.VideoSection {
  display: none;
}
@media only screen and (max-width: 1080px) {
  .VideoSection {
    display: initial;
  }
}
