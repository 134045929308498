.SecondSection {
  display: flex;
  color: black;
  overflow: hidden;
}
.Leftsection {
  width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 3%;
  /* height: 100vh; */
}
.Leftsection h4 {
  /* margin-left: 40px; */
  font-weight: 400;
  /* margin: 0; */
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 15px;
  font-family: var(--primary-font-family);
}
.Leftsection h1 {
  margin-top: 10px;
  font-weight: 400;
  font-family: var(--heading-font-family);
}
.Leftsection p a {
  text-decoration: none;
  color: inherit;
}
.Leftsection p a:hover {
  text-decoration: underline;
}
.hr {
  width: 80%;
  height: 1px;
  background-color: black;
}
.Rightsection {
  width: 65%;
  /* height: 150vh; */
  background-image: url("../../img/others/BookNow1.webp");
  background-size: cover;
  display: flex;
  padding-top: 4em;
  justify-content: center;
  align-items: center;
}
.BookingSection {
  background-color: white;
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 4em;
}
.BookingSection h1 {
  font-weight: 400;
  font-family: var(--heading-font-family);
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.formGrid {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px; /* Adjust the gap between items as needed */
}
.formItem {
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
}
.formItem label {
  font-size: 17px;
}
.span2 {
  grid-column: span 2;
}
.BookingSection Input,
.BookingSection select,
.BookingSection textarea {
  padding: 15px 10px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  font-family: var(--primary-font-family);
}
.BookingSection select {
  font-size: 15px;
  font-family: var(--primary-font-family);
}
.BookingSection Input::placeholder,
.BookingSection textarea::placeholder {
  font-size: 15px;
  font-family: var(--primary-font-family);
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.BookingSection button {
  background-color: #7e8865;
  color: white;
  border: none;
  font-size: 20px;
  padding: 10px 35px;
  font-family: var(--primary-font-family);

  transition: all 0.5s ease;
}
.BookingSection button:hover {
  background-color: black;
}
@media only screen and (max-width: 1000px) {
  .SecondSection {
    flex-wrap: wrap;
  }
  .SecondSection .Leftsection {
    width: 100%;
  }
  .Leftsection .hr {
    margin-bottom: 2em;
  }
  .Rightsection {
    width: 100%;
  }
}
@media only screen and (max-width: 750px) {
  .formItem {
    grid-column: span 2;
  }
}
@media only screen and (max-width: 500px) {
  .container h1 {
    font-size: 25px;
  }
}
