.HeroSection {
  height: 97vh;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0;
  position: relative;
  font-family: var(--primary-font-family);
}
.HeroSection h1 {
  margin: 0;
  text-align: center;
  margin-top: 1em;
  font-size: 80px;
  font-weight: 400;
  font-family: var(--heading-font-family);
}
.HeroSection h4 {
  font-weight: 400;
  font-size: 21px;
  text-align: center;
}
@media only screen and (max-width: 1200px) {
  .HeroSection h1 {
    font-size: 70px;
  }
  .HeroSection {
    height: 85vh;
  }
}
@media only screen and (max-width: 850px) {
  .HeroSection h1 {
    font-size: 55px;
  }
  .HeroSection h4 {
    font-size: 15px;
  }
  .HeroSection {
    height: 70vh;
  }
}
@media only screen and (max-width: 550px) {
  .HeroSection h1 {
    font-size: 50px;
  }
  .HeroSection h4 {
    font-size: 13px;
  }
  .HeroSection {
    height: 60vh;
  }
}
@media only screen and (max-width: 400px) {
  .HeroSection h1 {
    font-size: 35px;
  }
  .HeroSection h4 {
    font-size: 13px;
  }
  .HeroSection {
    height: 50vh;
  }
}
