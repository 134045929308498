.TopSection {
  display: flex;
  margin-top: 4em;
  justify-content: center;
  align-items: center;
  color: black;
}
.LeftSection {
  width: 55%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.LeftSection iframe {
  width: 580px;
  height: 328px;
}
.RightSection {
  width: 40%;
}
.RightSection h1 {
  color: black;
  font-family: var(--heading-font-family);
  letter-spacing: 1.2px;
  font-size: 50px;
  font-weight: 400;
}
.RightSection h4 {
  color: var(--hap-base-color);
  font-family: var(--primary-font-family);
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
  line-height: 1.2;
}
@media only screen and (max-width: 1080px) {
  .LeftSection {
    width: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .LeftSection iframe {
    width: 500px;
    height: 282px;
  }
}
@media only screen and (max-width: 1030px) {
  .LeftSection {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .LeftSection iframe {
    width: 480px;
    height: 270px;
  }
}
@media only screen and (max-width: 1000px) {
  .TopSection {
    flex-direction: column-reverse;
  }
  .RightSection {
    width: 80%;
    text-align: center;
  }
  .LeftSection {
    width: 80%;
    justify-content: center;
  }
  .LeftSection iframe {
    width: 640px;
    height: 360px;
  }
}
@media only screen and (max-width: 700px) {
  .LeftSection iframe {
    width: 580px;
    height: 328px;
  }
}
@media only screen and (max-width: 630px) {
  .LeftSection iframe {
    width: 500px;
    height: 282px;
  }
}
@media only screen and (max-width: 570px) {
  .LeftSection iframe {
    width: 480px;
    height: 270px;
  }
}
@media only screen and (max-width: 520px) {
  .LeftSection iframe {
    width: 400px;
    height: 225px;
  }
}
@media only screen and (max-width: 430px) {
  .LeftSection iframe {
    width: 350px;
    height: 197px;
  }
}
@media only screen and (max-width: 400px) {
  .LeftSection iframe {
    width: 300px;
    height: 170px;
  }
}
@media only screen and (max-width: 280px) {
  .LeftSection iframe {
    width: 250px;
    height: 140px;
  }
}
