.AboutSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  color: black;
  margin: 3em 0em;
}
.LeftSection {
  width: 40%;
}
.LeftSection img {
  width: 100%;
  object-fit: cover;
}
.RightSection {
  width: 40%;
  color: black;
}
.RightSection .Subheading {
  color: var(--hap-base-color);
}
.Subheading {
  font-weight: 500;
  font-family: var(--primary-font-family);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.RightSection h1 {
  font-weight: 500;
  font-size: 50px;
  font-family: var(--heading-font-family);
}
.paragraph {
  font-size: 18px;
  text-align: justify;
}
.RestaurantSection {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  color: black;
  margin: 5em 0em;
}
.RestaurantSection .LeftSection {
  width: 70%;
}
.RestaurantSection .RightSection {
  width: 20%;
  margin-left: -80px;
}
.RestaurantSection .RightSection {
  background-color: black;
  color: white;
  padding: 60px 60px;
}
.RestaurantSection .RightSection h1 {
  font-size: 30px;
}
.reservation {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.reservation svg {
  color: #b99d75;
  font-size: 52px;
}
.reservation .reservation p {
  margin: 0;
}
.Description .Subheading {
  font-weight: 500;
  color: var(--hap-base-color);
  font-family: var(--primary-font-family);
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.Headingh1 {
  color: black;
  font-weight: 500;
  font-size: 45px;
  text-align: center;
  font-family: var(--heading-font-family);
}
.menuList {
  display: flex;
  justify-content: space-around;
}
.RightSection .Heading2,
.Heading2 {
  color: #b99d75;
  font-weight: 500;
  font-size: 35px;
  text-align: center;
  font-family: var(--heading-font-family);
}
