.TopFooter {
  background-color: rgb(0, 0, 0);
  color: white;
}
.gridcontainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* gap: 10px; */
}
.items {
  border: 0.25px solid #53624e62;
  padding: 45px;
  text-align: center;
}
.griditemfull {
  grid-column: span 4;
  border: 0.25px solid #53624e62;
  padding: 30px;
  text-align: center;
}
.itemheading {
  font-family: var(--primary-font-family);
  text-decoration: underline;
  color: #53624e;
  text-underline-offset: 5px;
  font-size: 15px;
}
.numberLink {
  display: flex;
  align-items: center;
  justify-content: center;
}
.TopFooter a {
  text-decoration: none;
  color: white;
}
.items p {
  margin: 0;
  margin-top: 10px;
  font-size: 15px;
}
.items p:hover {
  color: #b99d75;
}
.socialLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}

.socialLinks svg:hover {
  color: #b99d75;
}
/* .topSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
/* .items {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #53624e;
} */
@media only screen and (max-width: 1127px) {
  .items {
    grid-column: span 2;
  }
  .items {
    padding: 30px;
  }
}
@media only screen and (max-width: 610px) {
  .items {
    grid-column: span 4;
  }
  .items {
    padding: 25px;
  }
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
