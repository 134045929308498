.HeroSection {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0;
  position: relative;
  font-family: var(--primary-font-family);
}
.floatingdiv {
  position: absolute;
  bottom: -9%;
  padding: 25px 20px;
  background-color: var(--hap-base-color);
  width: 85%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}
.griditem {
  border: 1px solid #b99d75;
  /* padding: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-align: center;
}
.griditem p {
  font-size: 16px;
}
.griditem input {
  background-color: transparent;
  border: none;
  color: inherit;
  font-family: inherit;
  text-align: right;
  padding-left: 0px;
}
.griditem input:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.griditem input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(
    1
  ); /* Invert the color, you can replace this with the color you want */
  cursor: pointer; /* Add a pointer cursor for better user interaction */
}
.griditem:nth-child(3),
.griditem:nth-child(4) {
  justify-content: space-evenly;
  position: relative;
}
.spinner {
  position: absolute;
  bottom: -130%;
  background-color: white;
  border: 1px solid #b99d75;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: black;
}
.spinner2 {
  position: absolute;
  bottom: -220%;
  left: 0;
  background-color: white;
  border: 1px solid #b99d75;
  width: 120%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  color: black;
}
.spinner2column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.spinner input,
.spinner2 input {
  text-align: center;
}
.custominputspinner {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
}
.spinnerinput {
  width: 40px;
  text-align: center;
  margin: 0 5px;
  font-size: 18px;
}
.spinnerbutton {
  color: #b99d75;
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 24px;
}
.griditem:nth-child(5) {
  background-color: #b99d75;
  color: white;
}
.griditem:nth-child(5):hover {
  background-color: #846d4c;
}
.inneritem {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
}
.griditem p a {
  text-decoration: none;
  color: whitesmoke;
}
.FormDiv {
  background-color: var(--hap-base-color);
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  align-items: center;
  gap: 10px;
  display: none;
}

@media only screen and (max-width: 1200px) {
  .HeroSection {
    margin-top: 0;
  }
  .floatingdiv {
    display: none;
  }
  .FormDiv {
    display: initial;
    display: flex;
  }
  .griditem {
    width: 18%;
  }
}
@media only screen and (max-width: 1100px) {
  .FormDiv {
    flex-wrap: wrap;
  }
  .griditem {
    width: 30%;
  }
}
@media only screen and (max-width: 725px) {
  .griditem {
    width: 40%;
  }
  .spinner2 {
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .griditem {
    width: 80%;
  }
  .spinner {
    z-index: 99;
  }
  .spinner2 {
    width: 100%;
  }
}
