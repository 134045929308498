.CounterSection {
  background-image: url("../../img/others/Component19.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.CounterSection h1 {
  font-weight: 500;
  font-size: 45px;
  text-align: center;
  font-family: var(--heading-font-family);
}
.counter {
  display: flex;
  width: 60%;
  margin-top: 1em;
  justify-content: space-around;
}
.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.item h1 {
  font-size: 75px;
  margin: 0;
  letter-spacing: 0.1em;
}
.item p {
  margin: 0;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
@media only screen and (max-width: 1000px) {
  .CounterSection h1 {
    font-size: 40px;
  }
  .CounterSection .counter h1 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 800px) {
  .CounterSection h1 {
    font-size: 35px;
  }
  .CounterSection .counter h1 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 511px) {
  .counter {
    flex-direction: column;
    gap: 4em;
  }
  .CounterSection .counter h1 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 450px) {
  .CounterSection h1 {
    font-size: 30px;
  }
  .CounterSection .counter h1 {
    font-size: 40px;
  }
}
