.ContactPage {
  background-color: black;
}
.mapdiv {
  padding: 5em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  overflow: hidden;
}
.LeftSection {
  width: 50%;
  /* height: fit-content; */
}
.LeftSection iframe {
  width: 100%;
  /* height: 100%; */
  height: 600px;
}
.RightSection {
  width: 30%;
  padding: 20px;
}
.RightSection p a {
  text-decoration: none;
  color: inherit;
}
.RightSection p a:hover {
  text-decoration: underline;
}
.details {
  text-align: center;
  border: 1px solid#b99d75;
  padding: 0 50px;
  padding-bottom: 30px;
}
.ContactPage h5 {
  text-align: center;
  margin-top: 50px;
  font-weight: 400;
  color: #b99d75;
  letter-spacing: 1px;
  font-family: var(--primary-font-family);
}
.ContactPage h1 {
  text-align: center;
  font-weight: 400;
  font-family: var(--heading-font-family);
}
.ContactPage p {
  margin: 5px;
  text-align: center;
}
.socialLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: rgb(185, 184, 184);
  margin-top: 25px;
}
.socialLinks svg:hover {
  color: #b99d75;
}
.details .linklink a {
  text-decoration: none;
  color: inherit;
  position: relative;
  letter-spacing: 0.5px;
  font-family: var(--heading-font-family);
  font-size: 16px;
}
.details .linklink a::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 0.5px; /* Adjust the thickness of the underline as needed */
  background-color: #b99d75;
  transform-origin: bottom right;
  transform: scaleX(1);
  transition: transform 0.3s ease;
}
.details .linklink a:hover::before {
  transform-origin: bottom left;
  transform: scaleX(0);
}
.socialLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}
.socialLinks svg {
  color: #b99d75;
}
.socialLinks svg:hover {
  color: white;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media only screen and (max-width: 1000px) {
  .mapdiv {
    flex-wrap: wrap;
  }
  .LeftSection {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .RightSection {
    width: 100%;
  }
  .LeftSection iframe {
    width: 90%;
    /* height: 100%; */
    height: 600px;
  }
}
@media only screen and (max-width: 500px) {
  .LeftSection iframe {
    width: 90%;
    /* height: 100%; */
    height: 400px;
  }
}
