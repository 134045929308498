.OuterSection {
  background-color: var(--hap-base-color);
  display: grid;
  padding: 6em 4em;
  grid-template-columns: repeat(3, 1fr); /* Three columns with equal width */
  grid-template-rows: repeat(
    2,
    1fr
  ); /* Two rows with a fixed height of 100px each */
  gap: 2em; /* Add some gap between grid items */
  overflow: hidden;
}

/* Style the grid items */
.griditem {
  /* border: 1px solid #ccc; */
  padding: 10px;
  text-align: center;
  display: flex;
  gap: 10px;
}
.text h6 {
  font-family: var(--heading-font-family);
  font-size: 24px;
  font-weight: 400;
  margin: 0 0 0.5em 0;
  text-align: left;
}
.text p {
  margin: 0;
  text-align: left;
}
.logo {
  width: 50%;
}
.logo img {
  width: 75%;
}
@media only screen and (max-width: 1000px) {
  .OuterSection {
    padding-bottom: 3em;
    grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
  }
  .OuterSection {
    padding-bottom: 3em;
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media only screen and (max-width: 700px) {
  .OuterSection {
    padding-bottom: 3em;
    padding-left: 2em;
    padding-right: 2em;
    grid-template-columns: repeat(1, 1fr); /* Two columns with equal width */
  }
}
@media only screen and (max-width: 500px) {
  .OuterSection {
    padding-bottom: 3em;
    padding-left: 1em;
    padding-right: 1em;
  }
  .logo {
    width: 90%;
  }
  .logo img {
    width: 80%;
  }
}
