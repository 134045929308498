.TestimonialsSection {
  background-image: url("../../img/Home/Component20.webp");
  background-attachment: fixed;
  height: 80vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}
.leftArrowStyles {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 32px;
  font-size: 45px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
}
.containerStyles {
  width: 80%;
  height: 100%;
  margin: 0 auto;
}
.leftArrowStyles:hover {
  color: rgb(70, 70, 70);
}
.rightArrowStyles {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 32px;
  font-size: 45px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
}
.rightArrowStyles:hover {
  color: rgb(70, 70, 70);
}
.slides {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--primary-font-family);
}
.slides h3 {
  display: flex;
  gap: 5px;
  color: rgb(255, 247, 0);
}
.slides p {
  padding: 0 15%;
  font-size: 20px;
  text-align: center;
}
.slides h5 {
  letter-spacing: 1px;
  font-size: 15px;
  font-family: var(--heading-font-family);
}
@media only screen and (max-width: 900px) {
  .containerStyles {
    width: 90%;
    height: 100%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 500px) {
  .containerStyles {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
