.aboutsectionouter {
  color: white;
  padding: 8em 0 8em 0;
}
.aboutsection {
  /* height: 90vh; */
  /* background-color: var(--hap-title-color); */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.gridcontainer {
  display: grid;
  width: 88%;
  overflow: hidden;
  margin-bottom: 5em;
  grid-template-columns: repeat(3, 1fr);
}
.griditem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.griditem img:hover {
  transform: scale(1.2);
}
.griditem img {
  width: 100%;
  transform: scale(1.1);
  transition: transform 1s ease;
}
.griditem:nth-child(2) img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.griditem .overlap {
  background-color: #2c3529;
  opacity: 0.8;
  position: absolute;
  width: 100%; /* Set width to 100% */
  height: 100%; /* Set height to 100% */
  top: 0;
  z-index: 1;
  left: 0;
}
.griditem .overlapLine {
  position: absolute;
  width: 91.5%; /* Set width to 100% */
  height: 94%; /* Set height to 100% */
  top: 3%;
  left: 4%;
  z-index: 1;
  border: 1px solid #b99d75;
}
.griditem p {
  font-family: var(--primary-font-family);
  z-index: 1;
  width: 70%;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0 0 20px 0;
}

.explorepara {
  font-family: var(--primary-font-family);
  z-index: 1;
  width: 70%;
  font-size: 14px;
  letter-spacing: 0.5px;

  margin: 0 0 20px 0;
}
.griditem h1 {
  margin: 0 0 20px 0;
  font-family: var(--heading-font-family);
  z-index: 1;
  width: 90%;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0.25px;
  text-align: center;
}
.griditem p a,
.explorepara a {
  text-decoration: none;
  color: inherit;
  position: relative;
  font-family: var(--heading-font-family);
  font-size: 16px;
}
.griditem p a::before,
.explorepara a::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 0.5px; /* Adjust the thickness of the underline as needed */
  background-color: #b99d75;
  transform-origin: bottom right;
  transform: scaleX(1);
  transition: transform 0.3s ease;
}
.griditem p a:hover::before,
.explorepara a:hover::before {
  transform-origin: bottom left;
  transform: scaleX(0);
}

.aboutsection .selectRooms {
  width: 88%;
}
.selectRooms h4 {
  text-align: left;
  color: var(--hap-base-color);
  font-family: var(--primary-font-family);
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
  line-height: 1.2;
}
.selectRooms h1 {
  font-family: var(--heading-font-family);
  font-size: 3.75rem;
  line-height: 1.2;
  color: black;
  font-weight: 500;
  margin: 30px 0;
}
.selectRooms p {
  margin: 0;
  font-size: 18px;
  color: black;
  line-height: 1.66;
}
.RoomOptions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 3.5em 0em 1em 0em;
}
.RoomOptions .Item {
  width: 48%;
}
.RoomOptions .Item .Img {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.RoomOptions .Item .Img img {
  width: 100%;
  scale: 1.1;
  transition: transform 1s ease;
}
.RoomOptions .Item .Img img:hover {
  transform: scale(1.2);
}
.RoomOptions .Item .Img .PriceDiv {
  position: absolute;
  background-color: white;
  color: black;
  top: 15px;
  left: 15px;
  font-family: var(--heading-font-family);
}
.PriceDiv p {
  padding: 0px 5px;
}
.Item h1 {
  font-size: 35px;
  margin: 15px 0;
}
.Item .info {
  font-size: 25px;
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
}
.Item .info span {
  font-size: 15px;
  color: black;
  font-family: var(--heading-font-family);
  margin-right: 20px;
  margin-left: 0px;
}
.Item .info img {
  height: 27px;
}
.Item p {
  font-size: 17px;
  color: #333632;
  font-family: var(--primary-font-family);
}
.Item .info {
  flex-wrap: wrap;
}
.Item .info div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
@media only screen and (max-width: 1200px) {
  .aboutsectionouter {
    padding: 4em 0 2em 0;
  }
}
@media only screen and (max-width: 1080px) {
  .gridcontainer {
    display: none;
  }
}
@media only screen and (max-width: 1000px) {
  .LeftSection {
    text-align: center;
  }
  .LeftSection h4 {
    text-align: center;
  }
  .RoomOptions {
    flex-direction: column;
  }
  .RoomOptions .Item {
    margin-bottom: 2em;
    width: 70%;
  }
}
@media only screen and (max-width: 800px) {
  .LeftSection h1 {
    font-size: 50px;
  }
  .LeftSection p {
    display: inline;
  }
}
@media only screen and (max-width: 500px) {
  .RoomOptions .Item {
    width: 85%;
  }
}
